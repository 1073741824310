import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import '@fontsource/roboto-slab';

const theme = createMuiTheme({
  palette: {
    textColor: '#fff',
    primary: {
      main: '#424242'
    },
    background: {
      paper: '#424242'
    }
  },

  typography: {
    allVariants: {
      color: '#fff'
    },
    h4: {
      marginBottom: '1rem'
    },
    fontFamily: 'Roboto Slab'
  }
  
})


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
