import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, CardMedia, Box } from '@material-ui/core';


const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: '50%',
    marginBottom: '3em'
  },

  emphasis: {
    fontWeight: 700
  },

  error: {
    color: 'red',
  },

  appBar: {
    marginBottom: '3rem'
  },

  section: {
    marginBottom: '5rem'
  }
});

export default function Valheim() {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.section}>
        <Typography align="left" variant="h4">
          Join from Valheim Server Browser
        </Typography>

        <Card className={styles.card}>
          <CardContent>
            <Typography variant="body1">Use <code>103.214.108.12:27023</code> and <em className={styles.emphasis}>ask James or Steph for the password!</em></Typography>
          </CardContent>
        </Card>
      </Box>

      { /**  
      <Box className={styles.section}>
        <Typography align="left" variant="h4">
          Save the Server to Favorites
        </Typography>

        <Card className={styles.card}>
          <CardContent>
            <Typography align="left" variant="body1">
              1. Open up Steam, and click on the View menu at the top of the window.
        </Typography>
          </CardContent>
          <CardMedia component="img" width="50%" image="/images/valheim-1.jpg" />
        </Card>


        <Card className={styles.card}>
          <CardContent>
            <Typography align="left" variant="body1">
              2. Click the "Add a Server" button.
          </Typography>
          </CardContent>
          <CardMedia component="img" width="50%" image="/images/valheim-2.jpg" />
        </Card>

        <Card className={styles.card}>
          <CardContent>
            <Typography align="left" variant="body1">
              3. Paste in the following value: <code>103.214.108.12:27024</code> and click "ADD THIS ADDRESS TO FAVORITES".
          </Typography>
          </CardContent>
          <CardMedia component="img" width="50%" image="/images/valheim-3.jpg" />
        </Card>

        <Card className={styles.card}>
          <CardContent>
            <Typography align="left" variant="body1">
              4. Double click to join!  <em className={styles.emphasis}>Ask James or Steph for the password.</em>
            </Typography>
          </CardContent>
          <CardMedia component="img" width="50%" image="/images/valheim-4.jpg" />
        </Card>

        <Card className={styles.card}>
          <CardContent>
            <Typography align="left" variant="body1" className={`${styles.error} ${styles.emphasis}`}>
              5. If it doesn't work, click Refresh and try again.
          </Typography>
          </CardContent>
          <CardMedia component="img" width="50%" image="/images/valheim-5.jpg" />
        </Card>
      </Box>
      */}
    </>
  )
}