import './App.css';
import { AppBar, Tabs, Tab, Grid, Typography, Container } from '@material-ui/core';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import Valheim from './components/valheim';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  appBar: {
    marginBottom: '3rem'
  }
});

function App() {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();

  function onChange(evt, value) {
    history.push(value);
  }

  return (
    <Container maxWidth="lg">
      <AppBar position="static" className={styles.appBar}>

        <Tabs value={location.pathname} aria-label="simple tabs example" onChange={onChange}>

          <Tab value="/valheim" label="Valheim" />

          {/**<Tab value="/7days" label="7 Days to Die" /> **/}

        </Tabs>

      </AppBar>

      <Switch>
        <Redirect exact from="/" to="/valheim" />

        <Route default path="/7days">
          <Grid container direction="row" spacing={1}>

            <Grid item>

              <Typography align="left" variant="h1">
                7 Days to Die
              </Typography>

              <Typography align="left" variant="h4">
                Server Information
              </Typography>

              <Typography align="left" variant="h2">
                Plugins
              </Typography>

              <Typography align="left" variant="body1" paragraph>
                You'll need to install these plugins.
              </Typography>

            </Grid>
          </Grid>

        </Route>

        <Route path="/valheim">
          <Valheim />
        </Route>

      </Switch>
    </Container>
  );
}

export default App;
